import React, { useContext } from "react";
import "./index.css";
import { isBrowser } from "../../../utils/general";
import Moment from "react-moment";
import { useMediaQuery } from "react-responsive";
import { LanguageContext } from "../../../lang";

const ReviewBooking = ({ ride }) => {
  const { step1, step2, step3, step4 } = ride;

  let { pickupLoc } = step1 ? step1 : {};
  let splitLocation1 = pickupLoc ? pickupLoc.split(",") : "";
  let firstValue1 = splitLocation1[0];
  let restOfArray1 = splitLocation1.slice(1).toString();

  let { destLoc } = step1 ? step1 : {};
  let splitLocation2 = destLoc ? destLoc.split(",") : "";
  let firstValue2 = splitLocation2[0];
  let restOfArray2 = splitLocation2.slice(1).toString();
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const {
    translations: { rideAccepted },
  } = useContext(LanguageContext);
  const {
    reviewBookingText,
    outwardText,
    numOfTravelersText,
    contactDetailsText,
    totalText,
    btnText,
    taxiDetailText,
    driverDetailsText,
    modelText,
    licenceText,
    colorText,
    nameText,
    phoneText,
  } = rideAccepted;
  return (
    <div className="reviewBookingContainer">
      <div className="col-lg-8 text-center mx-auto headerText">
        <h2>{reviewBookingText}</h2>
      </div>
      <div className="container-fluid no-gutters header">
        <div className="row ">
          <div className="col-7 col-md-10 outwardText">{outwardText}</div>
          <div className="col-5 col-md-2 headerPriceText">
            € {step2.estimateFair ? step2.estimateFair.netFare : 0}
          </div>
        </div>
      </div>

      <div className="bottomHeaderContainer">
        <div className="container mb-4">
          <div className="row no-gutters locationInnerContainer">
            <div className="col-2 col-md-1 ">
              <i className="fa fa-map-marker locIcon m-auto fa-3x "></i>
            </div>
            <div className="col-10  col-md-8 ">
              <div className="d-flex flex-column  justify-content-center">
                <div>
                  <span className="address">{firstValue1}</span>
                </div>
                <div>
                  <span className="subAddress">{restOfArray1}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mb-4">
          <div className="row no-gutters locationInnerContainer ">
            <div className="col-2 col-md-1 ">
              <i className="fa fa-map-marker locLargeIcon m-auto fa-3x "></i>
            </div>
            <div className="col-10  col-md-8 ">
              <div className="d-flex flex-column  justify-content-center">
                <span className="address">{firstValue2} </span>
                <span className="subAddress">{restOfArray2}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row no-gutters bottomContainer  ">
            <div className="col-sm-11 boldBluetext">{numOfTravelersText}</div>
          </div>
          <div className="row">
            <div className="col-sm greyishText">{step2.noOfPassengers}</div>
          </div>
        </div>
        <div className="horizontalLine"></div>

        <div className="container bottomContainer">
          <div className="row no-gutters  ">
            <div className="col-sm-11 boldBluetext">{contactDetailsText}</div>
          </div>
          <div className="row">
            <div className="col-sm greyishText">
              {step4 && step4.custFullName}
            </div>
          </div>
          <div className="row">
            <div className="col-sm greyishText">{step4 && step4.email}</div>
          </div>
          <div className="row">
            <div className="col-sm greyishText">{step4 && step4.custPhone}</div>
          </div>
        </div>
      </div>

      <div className="container-fluid no-gutters footer">
        <div className="row d-flex align-items-center ">
          <div className="col-sm-3 col-md-3 totalText">{totalText}</div>
          {isDesktopOrLaptop ? (
            <div className="col-sm-12 col-md-9 footerPriceText">
              <span>
                €{step2.estimateFair ? step2.estimateFair.netFare : 0}
              </span>
            </div>
          ) : (
            <div className="col-sm-12 col-md-9 footerPriceText2">
              <span>
                € {step2.estimateFair ? step2.estimateFair.netFare : 0}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReviewBooking;
