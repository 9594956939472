import React, { Component, useContext } from "react";
import MapSection from "./Map-Section";
import ReviewBooking from "./Review-Booking";
import BookingInfoSection from "./Booking-Info-Section";
import Button from "./Button";
import { useMediaQuery } from "react-responsive";
import { LanguageContext } from "../../lang";
import { useQuery } from "@apollo/react-hooks";
import { GET_RIDE_DATA } from "./graphql/queries";
import { isBrowser } from "../../utils/general";
import "./index.css";
import timer from "../Common/PaymentTimer";

const paymentTimer = (acceptedTime, onOver) => {
  let timeLeft = timer.isTimeOver(acceptedTime);
  console.log("");
  if (timeLeft >= 5) {
  } else {
    console.log("start count down...");
    timer.startCountDown(timeLeft, paymentTimer);
  }
};

const parseRideInfo = (ride) => {
  const {
    destLoc,
    pickupLoc,
    roundTrip,
    dropHouseNo,
    pickupHouseNo,
    destCoordinateX,
    pickupCoordinateY,
    pickupCoordinateX,
    destCoordinateY,
    discountAmount,
    discountPercent,
    distance,
    driverEarn,
    estimatedTimeInMins,
    netFare,
    priceKm,
    priceMin,
    startPrice,
    totalFare,
    custEmail,
    custFullName,
    custPhone,
    pickupDate,
    pickupDateForText,
    rideType,
    status,
  } = ride;
  return {
    step1: {
      destCoordinateX,
      destCoordinateY,
      destLoc,
      dropHouseNo,
      pickupCoordinateX,
      pickupCoordinateY,
      pickupHouseNo,
      pickupLoc,
      roundTrip,
    },
    step2: {
      estimateFair: {
        discountAmount,
        discountPercent,
        distanceKM: distance,
        driverEarn,
        estimatedTimeInMins,
        netFare,
        priceKm,
        priceMin,
        startPrice,
        totalFare,
        custEmail,
        custFullName,
        custPhone,
        pickupDate,
        pickupDateForText,
        rideType,
      },
    },
    step3: {
      pickupDate,
      pickupDateForText,
      rideType,
    },
    step4: {
      custEmail,
      custFullName,
      custPhone,
    },
    status,
  };
};

const OrderBooked = ({ state }) => {
  let rideId = isBrowser() && localStorage.getItem("XXXX");
  // if (!rideId) return <div>Something went wrong</div>;
  let { data, loading, error } = useQuery(GET_RIDE_DATA, {
    variables: {
      id: rideId,
    },
  });
  console.log("data : ", data);
  const rideData = parseRideInfo(data ? data.ride : {});
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const {
    translations: { rideAccepted },
  } = useContext(LanguageContext);

  const onPageLeave = (event) => {
    event.preventDefault();
    return "You'll lose your data!";
  };

  var markers = [];
  if (data && data.ride) {
    markers = [
      {
        lat: rideData.step1.pickupCoordinateY,
        lng: rideData.step1.pickupCoordinateX,
      },
      {
        lat: rideData.step1.destCoordinateY,
        lng: rideData.step1.destCoordinateX,
      },
    ];
    //paymentTimer(Number(data.ride.acceptedTime));
  }

  if (loading) return "loading...";

  // if (rideData.status && rideData.status !== "pending")
  //   return <div>Something went wrong</div>;

  return (
    <div className="container-fluid parentContainer">
      <head>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
      </head>
      <div className="container-fluid rowContainer">
        {isDesktopOrLaptop && markers.length && (
          <div className="col-md-12 px-0">
            <MapSection markers={markers} />
          </div>
        )}

        <div className="row bottomSection">
          <div className="col-md-5">
            <BookingInfoSection data={data && data.ride.driver} />
          </div>
          <div className="col-md-7 px-0">
            <ReviewBooking ride={rideData} />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end  offset-md-3">
        <Button />
      </div>
    </div>
  );
};

export default OrderBooked;
