import React, { useContext } from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/SEO/seo";
import RideAcceptedComponent from "../components/RideAccepted";

const RideAccepted = ({ location }) => {
  const { state = {} } = location;
  return (
    <Layout hideFooter>
      <SEO title="Ride Accepted" />
      <RideAcceptedComponent state={state} />
    </Layout>
  );
};

export default RideAccepted;
