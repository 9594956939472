import React, { useContext } from "react";
import "./index.css";
import { navigate } from "gatsby";
import { LanguageContext } from "../../../lang";

const Button = () => {
  const {
    translations: { paymentScreen },
  } = useContext(LanguageContext);
  const { buttonText } = paymentScreen;
  return (
    <div
      onClick={() => {
        navigate("/payment");
      }}
      className="btnContainer d-flex justify-content-center align-items-center mb-5"
    >
      <p className="payNowText">{buttonText}</p>
    </div>
  );
};

export default Button;
