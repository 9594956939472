import React, { useContext } from "react";
import "./index.css";
import { LanguageContext } from "../../../lang";

const BookingInfoSection = ({ data }) => {
	const {
		firstName,
		lastName,
		phone,
		taxiName,
		taxiModel,
		taxiLicenseNo,
		taxiColor,
	} = data ? data : {};
	const {
		translations: { rideAccepted },
	} = useContext(LanguageContext);
	const {
		outwardText,
		numOfTravelersText,
		contactDetailsText,
		totalText,
		btnText,
		taxiDetailText,
		driverDetailsText,
		modelText,
		licenceText,
		colorText,
		nameText,
		phoneText,
		taxi,
	} = rideAccepted;
	return (
		<div className="infoParentContainer">
			<div className="infoChildContainer">
				<div className="col-xl-12 text-center mx-auto ">
					<span className="blueHeadings">{`${taxi}`}</span>
					<div className="detailsText">
						<span>{`${modelText}: ${taxiModel} `}</span>
					</div>

					<div className=" detailsText2">
						<span>{` ${licenceText}: ${taxiLicenseNo} `}</span>
					</div>
					<div className="detailsText2">
						<span> {`${colorText}: ${taxiColor}`}</span>
					</div>
					<br />
					<br />
					<br />
				</div>

				<div className="col-xl-8 text-center mx-auto ">
					<h2 className="blueHeadings">{driverDetailsText}</h2>
					<div className="col-sm-12 detailsText">
						<span>{`${nameText}: ${firstName} ${lastName}`}</span>
					</div>
					<div className="detailsText">
						<span>
							{phoneText}: {`${phone}`}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookingInfoSection;
